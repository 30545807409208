import React from 'react'
import './about.css'
import ME1 from '../../assets/pic1.jpg'
import ME2 from '../../assets/pic2.jpg'

const About = () => {
  return (
    <section id='about'>
      <div className="aboutTitle">
        <br />
        <br />
        <h3>Get to know</h3>
        <h1 className='abouttitle'>About Me</h1>
      </div>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME2} alt="About Image" />
          </div>
        </div>

        <div className="about__text">
          <p>
            Hello! My name is Josh Koo. 
            I am an aspiring full-stack developer that loves to design things and bring my creativity to life.
            My passion for technology started ever since I first learned how to use a computer. Since then, I always knew I wanted to break into the tech field and expand my talents.

            As for now, my main focus is to gain useful experience through internships, and build useful applications to display my work and experience to this website.
            My hobbies consists of playing guitar, programming, and gaming.
          </p>
        </div>
      </div>
    </section>
  )
}

export default About