import React from 'react'
import './footer.css'
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {IoLogoTwitter} from 'react-icons/io'
import {BiLinkExternal} from 'react-icons/bi'


const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>Josh Koo</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About Me</a></li>
        <li><a href="https://drive.google.com/file/d/1sSXjFzg6EI71F1qo363KiJ9LynvrO2oQ/view?usp=sharing" target="_blank">Resume<BiLinkExternal/></a></li>
        <li><a href="#projects">Projects</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>
      <h4 className='follow__me'>My Socials</h4>
      
      
      <div className="footer__socials">
        
        <a href="https://www.facebook.com/josh.b.koo" target="_blank"><FaFacebookF/></a>
        <a href="https://www.instagram.com/josh__koo/" target="_blank"><FiInstagram/></a>
        <a href="https://twitter.com/koo9_j" target="_blank"><IoLogoTwitter/></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; 2022 By Josh Koo</small>
        <small> created in React</small>
      </div>
    </footer>
  )
}

export default Footer