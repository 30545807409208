import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {FiSmartphone} from 'react-icons/fi'
import { useRef } from 'react';
import emailjs from 'emailjs-com'

export const Contact = () => {
  const form = useRef();
  const [ isAlertVisible, setIsAlertVisible ] = React.useState(false);
      const handleButtonClick = () => {
          setIsAlertVisible(true);

                setTimeout(() => {
                    setIsAlertVisible(false);
                }, 3000);
      }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_x0w41r8', 'template_q144b4w', form.current, 'hVh4-cSEIXAfs463i')
    
    e.target.reset()
  };

  return (
    <section id="contact">
      <div className="contactTitle">
        <br />
        <br />
        <br />
        <h3>Get In Touch</h3>
        <h1 className='contacttitle2'>Contact Me</h1>
        <br />
      </div>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option a">
            <MdOutlineEmail className='contact__option-icon' />
            <h4>Drexel Email</h4>
            <h5>jk3756@drexel.edu</h5>
            <a href="mailto:jk3756@drexel.edu">Send a message</a>
          </article>

          <article className="contact__option b">
            <FiSmartphone className='contact__option-icon'/>
            <h4>Phone</h4>
            <h5>215-353-0923</h5>
            <a>Call/Send a message</a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS */}
        <form ref={form} onSubmit={sendEmail}>
            <input type="text" name='name' placeholder='Full name' required />
            <input type="email" name='email' placeholder='Email address' required />
            <textarea name="message" rows="7" placeholder='Enter a message' required></textarea>
            <div>
              <button onClick={handleButtonClick} type='submit' className='btn send'>Send Message</button> 
            {isAlertVisible && <div className='alert-container'>
                <div className='alert-inner'>Message has been sent!</div>
            </div>}
            </div>
        </form>
      </div>
    </section>
  )
}

export default Contact