import React from 'react'
import './projects.css'
import IMG1 from '../../assets/calendar1.png'
import IMG3 from '../../assets/unipass.png'
import IMG2 from '../../assets/personal1.png'
import IMG4 from '../../assets/discord1.png'

export const Projects = () => {
  return (
    <section id="projects">
      <br />
      <h1 class="projecttitle">Projects</h1>
      <h2 class="h2s">hover for more info :)</h2>
      <div className="container portfolio__container">
        <article className='card'>
          <div className="card__content">
            <h3 className='card__title'>UniPass</h3>
            <p className='card__description'>A desktop app for storing your account credentials in a secured vault. Create and save new account information so you don't forget another username or password.</p>
          </div>
        </article>

        <article className='card2'>
          <div className="card__content">
            <h3 className='card__title'>Planner App</h3>
            <p className='card__description'>A calendar GUI that enables users to create and enter important events for a specific day and time.</p>
          </div>
        </article>
        
        <article className='card3'>
          <div className="card__content">
            <h3 className='card__title'>Personal Website</h3>
            <p className='card__description'>Portfolio for future projects and experiences. Made with React.</p>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG4} alt="" />
          </div>
            <h3>Discord Bot that plays music from Youtube - Python</h3>
        </article>
      
      </div>
    </section>
  )
}

export default Projects