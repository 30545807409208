import React from 'react'
import './nav.css'
import {BiLinkExternal} from 'react-icons/bi'

import { useState } from 'react'

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
      <nav>
          <span className="nav__logo"><a href="#">Josh Koo</a></span>
          <div className="nav__right">
            <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav == '#about' ? 'active' : ''}>About</a>
            <a href="#projects" onClick={() => setActiveNav('#projects')} className={activeNav == '#projects' ? 'active' : ''}>Projects</a>
            <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav == '#contact' ? 'active' : ''}>Contact</a>
            <a href="https://drive.google.com/file/d/1sSXjFzg6EI71F1qo363KiJ9LynvrO2oQ/view?usp=sharing" target="_blank" onClick={() => setActiveNav('#resume')} className={activeNav == '#resume' ? 'active' : ''}>Resume<BiLinkExternal/></a>
          </div>
      
        <button type='button' className='hamburger'>
          <span className='line'></span>
          <span className='line'></span>
          <span className='line'></span>
        </button>
       
      
      </nav>

  )
}

export default Nav

