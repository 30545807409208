import React from 'react'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Resume from './components/resume/Resume'
import Projects from './components/projects/Projects'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import FloatingSocials from './FloatingSocials'


const App = () => {
  return (
    <>
        <Nav />
        <Header />
        <About />
        <Projects />
        <Contact />
        <Footer />
  
        <FloatingSocials />
    </>
  )
}

export default App