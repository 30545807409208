import React from 'react'
import {FiLinkedin} from 'react-icons/fi'
import {FiGithub} from 'react-icons/fi'
import {FiTwitter} from 'react-icons/fi'

const HeaderSocials = () => {
  return (
    <div className='FloatingSocials'>
        <a href="https://www.linkedin.com/in/joshua-koo-b84a25175/" target="_blank"><FiLinkedin/></a>
        <a href="https://github.com/Koojb" target="_blank"><FiGithub/></a>
        <a href="https://twitter.com/koo9_j" target="_blank"><FiTwitter/></a>
    </div>
    
  )
}

export default HeaderSocials