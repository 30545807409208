import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/me.png'



const Header = () => {
  return (
    <header>
      <div className="container header__container">
        {/*<div className="me">
          <img src={ME} alt="me" />
        </div> */}
        <div className="text">
          <h1>Hello I'm</h1>
          <h1 className='name'>Joshua Koo</h1>
          <h2 className="text-light">Software Engineer Intern</h2>
          <p>Welcome to my profile!  I am currently a student at Drexel University pursuing a Bachelors in Software Engineering.</p>
          <p>I created this website to build myself a portfolio and as a way to improve my web development skills.</p>
          <p>I aim to add more content as I gain more experience and build projects I am passionate about.</p>
          <p>Currently, I am enrolled in a co-op program where I look forward to participating in internships.</p> 
          <CTA />
        </div>
      </div>
      <div>
      </div>
      </header>
  )
}

export default Header